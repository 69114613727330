import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { NotFoundComponent } from '@my-tomorrows/core-errors';
import { HeaderStates, RouteData, UserType, authCanActivateGuard, canMatchRoleGuard } from '@my-tomorrows/shared-data';
import { environment } from '../environment/environment';
import { HomeComponent } from './components/home/home.component';
import { crtMemberGuard } from './route-guards/crt-member.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivateChild: [authCanActivateGuard()],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: '',
        loadChildren: () => import('@my-tomorrows/auth').then((c) => c.AUTH_ROUTES),
        data: {
          [RouteData.HeaderState]: HeaderStates.Hidden,
          [RouteData.Role]: [UserType.search, UserType.patient],
        },
      },
      {
        path: '',
        loadChildren: () => import('@my-tomorrows/auth').then((c) => c.SSO_AUTH_ROUTES),
        data: {
          [RouteData.HeaderState]: HeaderStates.Hidden,
        },
      },
      {
        path: 'home',
        children: [
          {
            path: '',
            component: HomeComponent,
            canMatch: [canMatchRoleGuard],
            data: {
              [RouteData.CanMatchRoles]: [UserType.hcp, UserType.search, UserType.siteManager, UserType.mytUser],
            },
          },
          {
            path: '',
            loadChildren: () => import('@my-tomorrows/patient-dashboard').then((c) => c.PATIENT_ROUTES),
            data: { [RouteData.Role]: [UserType.patient] },
          },
        ],
      },
      {
        path: 'search-results',
        loadChildren: () => import('@my-tomorrows/search-results').then((c) => c.getSearchRoutes(environment)),
      },
      {
        path: 'trial-details',
        loadChildren: () => import('@my-tomorrows/search-trial-details').then((c) => c.SEARCH_DETAILS_ROUTES),
      },
      {
        path: 'referrals',
        data: {
          [RouteData.FullScreenHeight]: true,
          [RouteData.Role]: [UserType.hcp],
        },
        loadChildren: () => import('@my-tomorrows/hcp-referral').then((c) => c.HCP_REFERRAL_ROUTES),
      },
      {
        path: 'hcp-clinical-referral',
        data: {
          [RouteData.FullScreenHeight]: true,
        },
        loadChildren: () => import('./routes-config/hcp-clinical-referral.routes').then((c) => c.HCP_CLINICAL_REFERRAL_ROUTES),
      },
      {
        path: 'my-trials',
        canActivate: [crtMemberGuard],
        loadChildren: () => import('@my-tomorrows/hcp-trials').then((c) => c.HCP_TRIAL_ROUTES),
      },
      {
        path: 'settings',
        loadChildren: () => import('@my-tomorrows/account').then((c) => c.ACCOUNT_ROUTES),
        data: {
          [RouteData.FullScreenHeight]: false,
          [RouteData.Role]: [UserType.hcp, UserType.patient],
        },
      },
      {
        path: 'eap',
        canActivate: [authCanActivateGuard(), MsalGuard],
        data: {
          [RouteData.Role]: [UserType.siteManager],
          [RouteData.HeaderState]: HeaderStates.Sticky,
        },
        loadChildren: () => import('@my-tomorrows/eap-tracker').then((c) => c.EAP_ROUTES),
      },
      {
        path: '**',
        component: NotFoundComponent,
        data: {
          [RouteData.FullScreenHeight]: true,
        },
      },
    ],
  },
];
