import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import { ButtonType, ButtonsComponent, ExpansionPanelComponent, IconSize, Icons, IconsComponent } from '@my-tomorrows/components';
import { FeatureFlagService, FeatureFlags, SharedAuthService, StorageService, UserType } from '@my-tomorrows/shared-data';
import {
  PRESCREENING_DISCLAIMER_DIALOG_STORAGE_KEY,
  PreScreeningDisclaimerDialogService,
  SearchNewComponent,
} from '@my-tomorrows/shared-search';
import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';
import { CARDS } from './home.constants';
import { HomeInfoCard } from './home.interface';

@Component({
  selector: 'myt-home',
  standalone: true,
  imports: [SearchNewComponent, TranslateModule, NgClass, IconsComponent, ButtonsComponent, NgTemplateOutlet, ExpansionPanelComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PreScreeningDisclaimerDialogService],
})
export class HomeComponent {
  private readonly router = inject(Router);
  private readonly sharedAuth = inject(SharedAuthService);
  private readonly storageService = inject(StorageService);
  private readonly disclaimerDialogService = inject(PreScreeningDisclaimerDialogService);
  readonly featureFlagService = inject(FeatureFlagService);

  readonly iconSize = IconSize;
  readonly buttonType = ButtonType;
  readonly cards: HomeInfoCard[] = CARDS;
  readonly icons: Icons[] = [Icons.Globe, Icons.Microscope, Icons.UiZoom];

  readonly isPrescreenEnabled = computed(
    () =>
      [UserType.hcp, UserType.search].includes(this.sharedAuth.$getUserType()) &&
      this.featureFlagService.isFeatureEnabled(FeatureFlags.Enable_PS_V2),
  );

  onSearchParamsChanges(newParams: Params): void {
    if (!newParams) {
      return;
    }

    Object.keys(newParams).forEach((key) => {
      if (!newParams[key]) {
        delete newParams[key];
      }
    });

    const route = newParams['profile'] ? '/search-results/pre-screening-check' : '/search-results';

    if (newParams['profile'] && !this.storageService.getItem(PRESCREENING_DISCLAIMER_DIALOG_STORAGE_KEY)) {
      this.disclaimerDialogService
        .openPrescreeningDisclaimerDialog()
        .afterClosed()
        .pipe(take(1))
        .subscribe((value) => {
          if (value) {
            this.storageService.setItem(PRESCREENING_DISCLAIMER_DIALOG_STORAGE_KEY, true);
            this.router.navigate([route], {
              queryParams: newParams,
              queryParamsHandling: 'merge',
            });
          }
        });
      return;
    }

    this.router.navigate([route], {
      queryParams: newParams,
      queryParamsHandling: 'merge',
    });
  }
}
