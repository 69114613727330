import { HomeInfoCard } from './home.interface';

export const CARDS: HomeInfoCard[] = [
  {
    imagePath: 'assets/images/talking-with-doctor.webp',
    link: 'https://mytomorrows.com/patients/',
  },
  {
    imagePath: 'assets/images/doctor-with-ipad.webp',
    link: 'https://mytomorrows.com/healthcare-professionals/',
  },
];
